import {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import '../assets/css/global.css';
import '@atlassian/learning-components/dist/styles/index.css';
import { RouteComponent } from 'react-resource-router';
import { Content } from '@atlaskit/page-layout';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import RelayEnvironment from '../services/relay';
import {
  setGlobalTheme,
  themeObjectToString,
  type ThemeState,
  themeStringToObject,
} from '@atlaskit/tokens';
import { getAuthUrl } from 'utils/url-generator';
import {
  CommunityApp,
  CommunityNavigation,
  LoggedInUser,
  SharedFooter,
} from '@atlassian/learning-components';

import { sentryConfig } from '../services/sentry';
import { css } from '@compiled/react';
import { ENVIRONMENT, getEnvironment } from 'utils/environment';
import { UserContext } from '../context/UserContext';
import {
  CommunityAnalyticsClient,
  eventType,
} from '@atlassian/community-library';
import { MOBILE, TABLET } from '../utils/constants';
import { championsIsActive } from '../services/statsig/featureGates';

if (typeof window !== 'undefined') {
  const Sentry = require('@sentry/react');
  Sentry.init(sentryConfig);
}

const getThemePreference = (): Partial<ThemeState> => {
  if (typeof window == 'undefined') {
    return { colorMode: 'light' };
  }
  const savedThemePreference = window.localStorage.getItem('theme');
  // legacy saved theme
  if (savedThemePreference === 'light' || savedThemePreference === 'dark') {
    return { colorMode: savedThemePreference };
  } else if (savedThemePreference !== null) {
    return themeStringToObject(savedThemePreference);
  }
  return getOsThemePreference();
};

const getOsThemePreference = (): Partial<ThemeState> => {
  const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
  if (darkModeMediaQuery.matches) {
    return { colorMode: 'dark' };
  }
  return { colorMode: 'light' };
};

const getAppByRoute = (route: string) => {
  if (route === '/events') {
    return CommunityApp.EVENTS;
  }
  if (route === '/champions') {
    return CommunityApp.CHAMPIONS
  }
  return CommunityApp.HOME;
};

// const history = createBrowserHistory();
const TopNavigationContents: FunctionComponent<{
  isLoggedIn: boolean;
  loggedInUser: LoggedInUser;
}> = ({ isLoggedIn, loggedInUser }) => {
  // using globalThis.location causes a hydration error since the location will be a
  // tesseract server when it renders on the server instead of community-ui.atlassian.com
  const homepage = process.env.HOME_BASE_URL;
  const pathname = globalThis.location.pathname;
  const currentPage = encodeURIComponent(
    `${homepage}${pathname.replace('index.html', '')}`,
  );

  let environment = getEnvironment(ENVIRONMENT.DEV);
  if (environment === ENVIRONMENT.LOCAL) {
    environment = ENVIRONMENT.DEV;
  }

  const loginHref = useMemo(
    () =>
      getAuthUrl('login', {
        redirectUrl: currentPage,
        experience: 'entry-auth',
        email: loggedInUser.email,
      }),
    [currentPage],
  );
  const logoutHref = useMemo(
    () =>
      getAuthUrl('logout', {
        redirectUrl: currentPage,
        experience: 'entry-auth',
        email: loggedInUser.email,
      }),
    [currentPage],
  );
  const signupHref = useMemo(
    () =>
      getAuthUrl('signup', {
        redirectUrl: currentPage,
        experience: 'entry-auth',
        email: loggedInUser.email,
      }),
    [currentPage],
  );

  // initialize analytics client
    useEffect(() => {
      CommunityAnalyticsClient.initWithPageAnalytics(
        CommunityAnalyticsClient.SUB_APP.HOME,
        null,
        getEnvironment() as ENVIRONMENT,
        {},
        loggedInUser.id,
      );
    }, []);

  return (
    <CommunityNavigation
      currentApp={getAppByRoute(pathname)}
      isLoggedIn={isLoggedIn}
      user={loggedInUser}
      loginHref={loginHref}
      logoutHref={logoutHref}
      signupHref={signupHref}
      onAnalyticsSendEvent={(type, payload) =>
        CommunityAnalyticsClient.sendEvent(
          type as unknown as eventType,
          payload,
        )
      }
      email={loggedInUser.email}
      showChampions={championsIsActive()}
    />
  );
};

const AppRouteComponent: FunctionComponent = () => {
  const data = useContext(UserContext);
  const user = data?.user;
  const normalizedUser: LoggedInUser = {
    id: user?.account_id || '',
    publicName: user?.name || '',
    avatar: user?.picture || '',
    email: user?.email || '',
  };
  return (
    <>
      <TopNavigationContents
        isLoggedIn={!!user}
        loggedInUser={normalizedUser}
      />
      <Content>
        <RouteComponent />
      </Content>
      <div css={footerSpacerStyles} />
      <SharedFooter isLoggedIn={!!user} email={user?.email || ''} showChampions={championsIsActive()} />
    </>
  );

  // TODO: Using PageLayout and TopNavigation cause issues with sticky nav bar width and scroll bar overlap
  // Re-evaluate if we need to use these components. If not, remove this code.
  // return (
  //   <>
  //     <PageLayout>
  //       <TopNavigation
  //         isFixed={true}
  //         id="app-navigation"
  //         skipLinkTitle="Navigation"
  //       >
  //         <TopNavigationContents
  //           isLoggedIn={!!user}
  //           loggedInUser={normalizedUser}
  //         />
  //       </TopNavigation>
  //       <Content>
  //         <RouteComponent />
  //       </Content>
  //     </PageLayout>
  //     <div css={footerSpacerStyles} />
  //     <SharedFooter />
  //   </>
  // );
};

export const App: FunctionComponent = () => {
  const themePreference = getThemePreference();
  const [theme] = useState<Partial<ThemeState>>(
    themePreference?.colorMode
      ? { colorMode: 'light', typography: 'typography-adg3' }
      : { colorMode: 'light', typography: 'typography-adg3' },
  );
  useEffect(() => {
    setGlobalTheme(theme);
    window.localStorage.setItem('theme', themeObjectToString(theme));
  }, [theme]);

  return (
    <div css={appStyles}>
      <RelayEnvironmentProvider environment={RelayEnvironment}>
        <AppRouteComponent />
      </RelayEnvironmentProvider>
    </div>
  );
};

const appStyles = css({
  '*': {
    boxSizing: 'border-box',
  },
});

const footerSpacerStyles = css({
  height: '112px',
  [TABLET.MEDIA_QUERY]: {
    height: '72px'
  },
  [MOBILE.MEDIA_QUERY]: {
    height: '64px',
  },
});
