import {
  Row1Image1,
  Row1Image2,
  Row1Image3,
  Row2Image1,
  Row2Image2,
  Row2Image3,
  Row3Image1,
  Row3Image2,
  Row3Image3,
} from 'assets/images';

import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { MOBILE, TABLET } from '../../utils/constants';
import SectionButton from '../common/SectionButton';

const CHAMPIONS_SIGNUP_URL =
  process.env.CHAMPIONS_SIGNUP_URL ||
  'https://atlassian.my.site.com/atlassianchampions/s/';

const ApplyButton = () => {
  return (
    <SectionButton
      href={CHAMPIONS_SIGNUP_URL}
      text="Apply now"
      target="_blank"
      hasArrow={false}
    />
  );
};

const items = [
  {
    image: Row1Image1,
    description:
      'Dedicated Atlassian Champion support and direct access to the Atlassian team.',
  },
  {
    image: Row1Image2,
    description:
      'Opportunity to earn a free ticket to Team and attend exclusive networking events.',
  },
  {
    image: Row1Image3,
    description: 'Access to a private Slack instance for Atlassian Champions.',
  },
  {
    image: Row2Image1,
    description:
      'Support and resources for running events – think content, swag for attendees, and reimbursement for food*.',
  },
  {
    image: Row2Image2,
    description:
      'Opportunity to join our Community Advisory Board, with Alpha access to new features, input on future enhancements and more.',
  },
  {
    image: Row2Image3,
    description:
      'Champions-only spaces online, plus extra permissions to moderate content and write articles.',
  },
  {
    image: Row3Image1,
    description: 'Atlassian Champion lozenge on your Community profile.',
  },
  {
    image: Row3Image2,
    description: "Free vouchers for Atlassian's certification exams.",
  },
  {
    image: Row3Image3,
    description: 'Fame and glory (arguably the coolest of the benefits!).',
  },
];

const triangleDecoration = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="673"
    height="40"
    viewBox="0 0 673 40"
    fill="none"
  >
    <path d="M0 0L673 40H0V0Z" fill="#E9F2FE" />
  </svg>
);

const triangleDecorationMobile = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="207"
    height="40"
    viewBox="0 0 207 40"
    fill="none"
  >
    <path d="M0.205811 0L207.093 40H0.205811V0Z" fill="#E9F2FE" />
  </svg>
);

export const ChampionsAwards = () => {
  return (
    <section css={containerStyle}>
      <div css={cardStyle}>
        <div css={triangleDecorationStyle}>
          <div css={desktopTriangleStyle}>{triangleDecoration}</div>
          <div css={mobileTriangleStyle}>{triangleDecorationMobile}</div>
        </div>
        <h1 css={headingStyle}>Get exclusive access and rewards</h1>

        <div css={gridStyle}>
          {items.map((item, index) => (
            <div key={index} css={itemStyle}>
              <div css={imageStyle}>
                <img src={item.image} />
              </div>
              <p css={descriptionStyle}>{item.description}</p>
            </div>
          ))}
        </div>

        <p css={disclaimerStyle}>
          *Only eligible for Community Events open to the public
        </p>

        <div css={buttonContainerStyle}>
          <ApplyButton />
        </div>
      </div>
    </section>
  );
};

const containerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 40px;
  padding: 80px 20px 40px;

  ${TABLET.MEDIA_QUERY} {
    padding: 60px 16px 30px;
  }

  ${MOBILE.MEDIA_QUERY} {
    padding: 40px 12px 20px;
  }
`;

const cardStyle = css`
  background-color: #e9f2fe;
  padding-left: 104px;
  padding-right: 104px;
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;
  max-width: 1022px;
  width: 100%;

  ${TABLET.MEDIA_QUERY} {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  ${MOBILE.MEDIA_QUERY} {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

const headingStyle = css`
  text-align: center;
  margin-bottom: 40px;
  color: #101214;
  text-align: center;
  font-family: 'Charlie Display';
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.12px;

  ${TABLET.MEDIA_QUERY} {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 32px;
  }

  ${MOBILE.MEDIA_QUERY} {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 24px;
  }
`;

const gridStyle = css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${token('space.300')};
  margin-bottom: ${token('space.300')};

  ${TABLET.MEDIA_QUERY} {
    grid-template-columns: repeat(2, 1fr);
    gap: ${token('space.200')};
  }

  ${MOBILE.MEDIA_QUERY} {
    grid-template-columns: 1fr;
    gap: ${token('space.200')};
  }
`;

const itemStyle = css`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const imageStyle = css`
  height: 75px;
  width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;

  img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
`;

const descriptionStyle = css`
  font-size: 14px;
  line-height: 1.5;
  color: #000;
  text-align: center;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: -1px;
  margin-bottom: 12px;
`;

const disclaimerStyle = css`
  font-size: 14px;
  margin-bottom: ${token('space.400')};
  text-align: center;
  color: #000;
  font-family: 'Inter';
  font-weight: 400;
`;

const buttonContainerStyle = css`
  display: flex;
  justify-content: center;
`;

const triangleDecorationStyle = css`
  position: absolute;
  top: -40px;
  right: 0;
  display: block;
  line-height: 0;
`;

const desktopTriangleStyle = css`
  display: block;

  ${MOBILE.MEDIA_QUERY} {
    display: none;
  }
`;

const mobileTriangleStyle = css`
  display: none;

  ${MOBILE.MEDIA_QUERY} {
    display: block;
  }
`;
