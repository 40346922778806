import React from 'react';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import Image from '@atlaskit/image';
import SectionLink from 'components/common/SectionLink';
import { UITrackEventPayload } from '@atlassian/community-library';
import { LinkData } from 'components/common/analytics-wrapped/LinkData';
import { MOBILE, TABLET } from '../../utils/constants';

type GetStartedCardProps = {
  id: string;
  title: string;
  image: string;
  description: string;
  linkData: LinkData;
};

const GetStartedCard: React.FC<GetStartedCardProps> = ({
  id,
  title,
  image,
  description,
  linkData: { href, text: linkText, target },
}) => (
  <div css={cardContainerStyle}>
    <div css={imageContainerStyle}>
      <Image src={image} alt={linkText} css={imageStyle} />
    </div>
    <span css={titleStyle}>{title}</span>
    <p css={descriptionStyle}>{description}</p>
    <div css={sectionLinkContainerStyle}>
      <SectionLink
        href={href}
        text={linkText}
        {...(target ? { target } : {})}
        clickEventPayload={getLinkEventPayload('clicked', href, linkText, id)}
        hoverEventPayload={getLinkEventPayload('hovered', href, linkText, id)}
      />
    </div>
  </div>
);

const getLinkEventPayload = (
  action: string,
  href: string,
  label: string,
  id: string,
): UITrackEventPayload => {
  return {
    action,
    actionSubject: 'link',
    actionSubjectId: 'communityHomeGetStartedCardLink',
    source: 'communityHomeGetStartedSection',
    attributes: {
      href,
      label,
      communityCardId: id,
    },
  } as UITrackEventPayload;
};

const titleStyle = css({
  fontFamily: 'Charlie Display, sans-serif',
  fontSize: '32px',
  fontWeight: 700,
  lineHeight: '40px',
  [MOBILE.MEDIA_QUERY]: {
    fontSize: '24px',
    lineHeight: '32px',
  },
});

const cardContainerStyle = css({
  height: '406px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '16px 8px',
  [TABLET.MEDIA_QUERY]: {
    height: 'auto',
  },
  [MOBILE.MEDIA_QUERY]: {
    height: '295px',
    padding: '8px 0',
  },
});

const descriptionStyle = css({
  fontSize: '20px',
  fontFamily: 'Inter',
  color: token('color.text.subtle'),
  lineHeight: '32px',
  margin: '16px 0',
  [MOBILE.MEDIA_QUERY]: {
    fontSize: '16px',
    lineHeight: '24px',
    height: 'auto',
  },
});

const imageContainerStyle = css({
  width: '110px',
  height: '110px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '16px',
});

const imageStyle = css({
  maxHeight: '100%',
});

const sectionLinkContainerStyle = css({
  display: 'flex',
  alignItems: 'flex-end',
  height: '80px',
  '@media (max-width: 1279px)': {
    height: '48px',
  },
});

export default GetStartedCard;
