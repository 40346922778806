import { FunctionComponent, useMemo } from 'react';
import Section from '../../common/Section';
import { ChampionsCollage } from 'assets/images';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { MOBILE } from '../../../utils/constants';
import { championsIsActive } from 'services/statsig/featureGates';

const CHAMPIONS_TEXT = `Be the change. Help grow our global community and spark new ways of working in your organization through our Atlassian Champions program.`;

const ChampionsSection: FunctionComponent = () => {
  const isChampionsActive = useMemo(() => championsIsActive(), []);
  if (!isChampionsActive) {
    return <></>;
  }
  return (
    <Section
      id="champions"
      title="Champions"
      heading="Become an Atlassian Champion"
      buttonData={{
        href: '/champions',
        text: 'Learn about Champions',
        target: '_self',
      }}
    >
      <div css={contentStyle}>
        <div css={leftContentStyle}>
          <p css={innerTextStyle}>{CHAMPIONS_TEXT}</p>
        </div>
        <div css={rightContentStyle}>
          <img
            css={championsCollageStyle}
            src={ChampionsCollage}
            alt="Collage of Atlassian Champions"
          />
        </div>
      </div>
    </Section>
  );
};

export default ChampionsSection;

const contentStyle = css({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: '0 40px',
  [MOBILE.MEDIA_QUERY]: {
    flexDirection: 'column',
    gap: '16px',
    padding: '0',
  },
});

const leftContentStyle = css({
  width: '43%',
  display: 'flex',
  alignItems: 'center',
  [MOBILE.MEDIA_QUERY]: {
    width: '100%',
  },
});

const innerTextStyle = css({
  fontFamily: 'Inter, sans-serif',
  fontSize: '20px',
  color: token('color.text.subtle'),
  lineHeight: '32px',
  textAlign: 'left',
  marginRight: '10px',
  [MOBILE.MEDIA_QUERY]: {
    fontSize: '16px',
    lineHeight: '24px',
    marginLeft: '16px',
    marginRight: '16px',
  },
});

const rightContentStyle = css({
  width: '57%',
  [MOBILE.MEDIA_QUERY]: {
    width: '100%',
  },
});

const championsCollageStyle = css({
  width: '100%',
});
