import { FunctionComponent, useContext, useEffect, useMemo } from 'react';
import { css } from '@compiled/react';
import GetStartedCard from 'components/get-started-card/GetStartedCard';
import { LinkData } from 'components/common/analytics-wrapped/LinkData';
import {
  ForumsSpot,
  LearningSpot,
  EventsSpot,
  ChampionsSpot,
} from 'assets/images';
import {
  championsIsActive,
  uppUiFeatureGateTestIsActive,
} from 'services/statsig/featureGates';
import { uppTestExperimentIsActive } from 'services/statsig/experiments';
import { MOBILE, TABLET } from '../../../../utils/constants';
import { UserContext } from '../../../../context/UserContext';
import { getForumsSignInUrl } from '../../../../utils/url-generator';
import { User } from 'typings/types';

// all process.env variables has to be declared this way to make sure atlaspack
// can inline them properly
const FORUMS_BASE_URL = process.env.FORUMS_BASE_URL;
const LEARNING_BASE_URL = process.env.LEARNING_BASE_URL;
const EVENTS_BASE_URL = process.env.EVENTS_BASE_URL;

const TITLE = 'Get started';

const getCards = (user?: User | null | undefined) => {
  const isLoggedIn = !!user;
  return [
    {
      id: 'forums',
      title: 'Forums',
      image: ForumsSpot,
      description:
        'Connect with other community members sharing knowledge and expertise.',
      linkData: {
        href: isLoggedIn
          ? getForumsSignInUrl({
              redirectUrl: FORUMS_BASE_URL,
              email: user?.email,
            })
          : FORUMS_BASE_URL,
        text: 'Find answers',
        target: '_self',
      } as LinkData,
    },
    {
      id: 'learning',
      title: 'Learning',
      image: LearningSpot,
      description:
        'Build knowledge and skills to boost your productivity and empower your team.',
      linkData: {
        href: LEARNING_BASE_URL || '',
        text: 'Start learning',
        target: '_self',
      } as LinkData,
    },
    {
      id: 'events',
      title: 'Events',
      image: EventsSpot,
      description:
        'Meet, learn, and swap product tips at a community-hosted event, in your city or online.',
      linkData: {
        href: EVENTS_BASE_URL || '',
        text: 'Find an event',
        target: '_self',
      } as LinkData,
    },
    {
      id: 'champions',
      title: 'Champions',
      image: ChampionsSpot,
      description:
        'Become an Atlassian Champion to earn recognition and access exclusive resources.',
      linkData: {
        href: '/champions',
        text: 'Explore program',
        target: '_self',
      } as LinkData,
    },
  ];
};

// TODO: Clean up once Champions feature gate is removed.
// @compiled/react requires that styles be statically defined at compile time - need to make conditional around elements
// can't do:   const cardStyle = (cards.length === 3) ? cardsContainer3CardsStyle : cardsContainer4CardsStyle;
const renderCards = (cards: any[]) => {
  if (cards.length === 3) {
    return (
      <div css={cardsContainer3CardsStyle}>
        {cards.map((card) => (
          <GetStartedCard key={card.id} {...card} />
        ))}
      </div>
    );
  }
  return (
    <div css={cardsContainer4CardsStyle}>
      {cards.map((card) => (
        <GetStartedCard key={card.id} {...card} />
      ))}
    </div>
  );
};

const GetStarted: FunctionComponent = () => {
  const data = useContext(UserContext);
  const user = data?.user;
  const CARDS = useMemo(() => getCards(user), [user]);
  const isChampionsActive = useMemo(() => championsIsActive(), []);
  const filteredCards = useMemo(() => {
    return isChampionsActive
      ? CARDS
      : CARDS.filter((card) => card.id !== 'champions');
  }, [CARDS, isChampionsActive]);

  useEffect(() => {
    // temporary statsig test - check if the feature gate and experiment are enabled
    if (uppUiFeatureGateTestIsActive()) {
      console.log(
        'upp-ui-feature-gate-test is enabled - in GetStarted component',
      );
    }
    if (uppTestExperimentIsActive()) {
      console.log('upp-test-experiment is enabled - in GetStarted component');
    }
  }, []);

  return (
    <div css={containerStyle}>
      <h1 css={headingStyle}>{TITLE}</h1>
      {renderCards(filteredCards)}
    </div>
  );
};

const headingStyle = css({
  textAlign: 'center',
  fontFamily: 'Charlie Display,sans-serif',
  fontSize: '40px',
  fontWeight: 700,
  lineHeight: '48px',
  letterSpacing: '-0.12px',
  marginTop: '0px',
  '@media (max-width: 1279px)': {
    fontSize: '32px',
  },
});

const containerStyle = css({
  background: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 'var(--Spacing-16, 16px)',
  border: '2px solid var(--neutral-new-200, #F0F1F2)',
  padding: '40px 24px',
  zIndex: '1',
  marginTop: '68px',
  [TABLET.MEDIA_QUERY]: {
    margin: '24px',
    paddingBottom: '24px',
  },
  [MOBILE.MEDIA_QUERY]: {
    margin: '16px',
    padding: '24px',
  },
});

// TODO: Clean up once Champions feature gate is removed.
// @compiled/react requires that styles be statically defined at compile time - need to define this way
const cardsContainer3CardsStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px',
  '& > *': {
    flex: '1 1 0',
  },
  [TABLET.MEDIA_QUERY]: {
    gap: '0',
    '& > *': {
      flex: '1 1 calc(33.33% - 10.66px)', // Adjust for 3 cards
    },
  },
  [MOBILE.MEDIA_QUERY]: {
    gap: '8px',
    flexDirection: 'column',
    '& > *': {
      flex: '1 1 100%',
    },
  },
});

const cardsContainer4CardsStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px',
  '& > *': {
    flex: '1 1 0',
  },
  [TABLET.MEDIA_QUERY]: {
    gap: '0',
    '& > *': {
      flex: '1 1 calc(50% - 8px)', // 16px gap divided by 2
    },
  },
  [MOBILE.MEDIA_QUERY]: {
    gap: '8px',
    flexDirection: 'column',
    '& > *': {
      flex: '1 1 100%',
    },
  },
});

export default GetStarted;
