/** Landing page **/
import ForumsSpot from './homepage-forums-spot.svg';
import EventsSpot from './homepage-events-spot.svg';
import LearningSpot from './homepage-learning-spot.svg';
import ChampionsSpot from './homepage-champions-spot.svg';
import HeroGALeftCluster from './hero-ga-left-cluster.png';
import HeroGARightCluster from './hero-ga-right-cluster.png';
import HeroGAMobileTopCluster from './hero-ga-mobile-top-cluster.png';
import HeroGAMobileBottomCluster from './hero-ga-mobile-bottom-cluster.png';
import HeroLeftScribble from './hero-left-scribble.png';
import HeroRightScribble from './hero-right-scribble.png';
import HeroDesktopIcons from './hero-desktop-icons.png';
import HeroTabletIcons from './hero-tablet-icons.png';
import HeroMobileIcons from './hero-mobile-icons.png';
import ForumsContent from './forums-content.svg';
import LearningImage from './learning-image.png';
import ForumsQuestionLeft from './forums-question-left.svg';
import ForumsQuestionRight from './forums-question-right.svg';
import JiraLogoMark from './jira-logo-mark.svg';
import RovoLogoMark from './rovo-logo-mark.svg';
import LearningIllustration from './learning-illustration.png';
import ChampionsCollage from './champions-collage.png';

/** Events page **/
import EventsHeroArtworkLeftDesktop from './events-hero-artwork-left-desktop.svg';
import EventsHeroArtworkLeftMobile from './events-hero-artwork-left-mobile.svg';
import EventsHeroArtworkLeftTablet from './events-hero-artwork-left-tablet.svg';
import EventsHeroArtworkRightDesktop from './events-hero-artwork-right-desktop.svg';
import CommunityLedClasses from './events-images/events-for-everyone/community-led-classes.svg';
import CompanyUserGroups from './events-images/events-for-everyone/company-user-groups.svg';
import DeveloperEvents from './events-images/events-for-everyone/developer-events.svg';
import LocalMeetups from './events-images/events-for-everyone/local-meetups.svg';
import Discounts from './events-images/get-involved/discounts.svg';
import Flags from './events-images/get-involved/flags.svg';
import InsideScoop from './events-images/get-involved/inside-scoop.svg';
import Networking from './events-images/get-involved/networking.svg';
import NewSkills from './events-images/get-involved/new-skills.svg';
import Swag from './events-images/get-involved/swag.svg';
import ReadyToGetInvolved from './events-images/ready-to-get-involved.svg';

import EventsHeroArtworkRightMobile from './events-hero-artwork-right-mobile.png';
import EventsHeroArtworkRightTablet from './events-hero-artwork-right-tablet.png';

/** Miscellaneous **/
import AtlassianLogoFull from './atlassian-logo-full.svg';
import DangerTriangle from './danger-triangle.svg';
import FooterBackground from './footer-background.png';
import Gesture from './gesture.svg';
import WelcomeModalCoverImage from './welcome-image.png';
import AceLogo from './ace-logo.svg';

/** Champions page **/
import AdvocatingIcon from './champions/advocating-icon.png';
import ConnectingIcon from './champions/connecting-icon.svg';
import MentoringIcon from './champions/mentoring-icon.svg';
import DrivingChangeIcon from './champions/driving-change-icon.svg';
import ChampionsHeroArtworkLeftDesktop from './champions/champions-left-artwork-desktop.svg';
import ChampionsHeroArtworkLeftMobile from './champions/champions-left-artwork-mobile.svg';
import ChampionsHeroArtworkLeftTablet from './champions/champions-left-artwork-tablet.svg';
import ChampionsHeroArtworkRightDesktop from './champions/champions-right-artwork-desktop.svg';
import ChampionsHeroArtworkRightMobile from './champions/champions-right-artwork-mobile.svg';
import ChampionsHeroArtworkRightTablet from './champions/champions-right-artwork-tablet.svg';
import List1Icon1 from './champions/list1-icon1.svg';
import List1Icon2 from './champions/list1-icon2.svg';
import List1Icon3 from './champions/list1-icon3.svg';
import List1Icon4 from './champions/list1-icon4.svg';
import List1Icon5 from './champions/list1-icon5.svg';
import List2Icon1 from './champions/list2-icon1.svg';
import List2Icon2 from './champions/list2-icon2.svg';
import List2Icon3 from './champions/list2-icon3.svg';
import List2Icon4 from './champions/list2-icon4.svg';
import List2Icon5 from './champions/list2-icon5.svg';
import ArrowImage from './champions/arrow.png';
import Row1Image1 from './champions/row1-image1.svg';
import Row1Image2 from './champions/row1-image2.svg';
import Row1Image3 from './champions/row1-image3.svg';
import Row2Image1 from './champions/row2-image1.svg';
import Row2Image2 from './champions/row2-image2.svg';
import Row2Image3 from './champions/row2-image3.svg';
import Row3Image1 from './champions/row3-image1.svg';
import Row3Image2 from './champions/row3-image2.svg';
import Row3Image3 from './champions/row3-image3.svg';
import JimmyImage from './champions/jimmy.png';

export {
  /** Landing page **/
  ForumsSpot,
  EventsSpot,
  LearningSpot,
  ChampionsSpot,
  HeroGALeftCluster,
  HeroGARightCluster,
  HeroGAMobileTopCluster,
  HeroGAMobileBottomCluster,
  HeroLeftScribble,
  HeroRightScribble,
  HeroDesktopIcons,
  HeroTabletIcons,
  HeroMobileIcons,
  ForumsContent,
  LearningImage,
  ForumsQuestionLeft,
  ForumsQuestionRight,
  JiraLogoMark,
  RovoLogoMark,
  LearningIllustration,
  ChampionsCollage,
  /** Events page **/
  EventsHeroArtworkLeftDesktop,
  EventsHeroArtworkLeftMobile,
  EventsHeroArtworkLeftTablet,
  EventsHeroArtworkRightDesktop,
  CommunityLedClasses,
  CompanyUserGroups,
  DeveloperEvents,
  LocalMeetups,
  Discounts,
  Flags,
  InsideScoop,
  Networking,
  NewSkills,
  Swag,
  ReadyToGetInvolved,
  EventsHeroArtworkRightMobile,
  EventsHeroArtworkRightTablet,
  /** Miscellaneous **/
  AtlassianLogoFull,
  DangerTriangle,
  FooterBackground,
  Gesture,
  WelcomeModalCoverImage,
  AceLogo,
  /** Champions page **/
  ChampionsHeroArtworkLeftDesktop,
  ChampionsHeroArtworkLeftMobile,
  ChampionsHeroArtworkLeftTablet,
  ChampionsHeroArtworkRightDesktop,
  ChampionsHeroArtworkRightMobile,
  ChampionsHeroArtworkRightTablet,
  AdvocatingIcon,
  ConnectingIcon,
  MentoringIcon,
  DrivingChangeIcon,
  List1Icon1,
  List1Icon2,
  List1Icon3,
  List1Icon4,
  List1Icon5,
  List2Icon1,
  List2Icon2,
  List2Icon3,
  List2Icon4,
  List2Icon5,
  ArrowImage,
  Row1Image1,
  Row1Image2,
  Row1Image3,
  Row2Image1,
  Row2Image2,
  Row2Image3,
  Row3Image1,
  Row3Image2,
  Row3Image3,
  JimmyImage,
};
